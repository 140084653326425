<template>
  <v-card class="pa-0 text-center" rounded="md">
    <v-card-title v-if="account.logo" :style="`background: ${colorBackground ? colorBackground : account.color_background}`">
      <v-row justify="center">
        <v-sheet class="overflow-hidden" width="90" color="white" rounded="circle">
          <v-responsive class="align-center" :aspect-ratio="1/1">
            <v-sheet class="px-1" color="white" width="90">
              <v-img :src="account.logo.split(/[?#]/)[0]" width="100%" />
            </v-sheet>
          </v-responsive>
        </v-sheet>
      </v-row>
    </v-card-title>
    <v-card-subtitle :style="`background: ${colorBackground ? colorBackground : account.color_background}`">
      <v-row>
        <v-col class="pb-0 text-center" cols="12">
          <span class="d-block subtitle-1 white--text font-weight-medium mt-1">{{instance.document.name}} Nº {{instance.number}} de {{account.name}}</span>
          <span class="d-block body-2 white--text font-weight-regular">para {{instance.documents.customer.name}}</span>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text class="py-0">
      <v-row>
        <v-col cols="12">
          <span class="d-block body-1 font-weight-medium">{{instance.total | currency(instance.documents.currency)}} {{instance.documents.currency}} del {{instance.date | date}}</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text class="py-0 pb-2">
      <v-row>
        <v-col class="pa-0" cols="12">
          <v-textarea class="elevation-0" v-model="messageBody" @input="updateMessage($event)" :placeholder="placeholder" auto-grow solo flat hide-details />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import ReminderViewMixin from '@/mixins/ReminderViewMixin'
export default {
  mixins: [
    ReminderViewMixin
  ]
}
</script>